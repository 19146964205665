import React from 'react'
import TheatreNavbar from '../../../Components/Navbar/TheatreNavbar'
import Otp from '../../../Components/Theatre/Otp/Otp'

function TheatreOtp() {
  return (
    <>
    <TheatreNavbar/>
    <Otp/>
    </>
  )
}

export default TheatreOtp
