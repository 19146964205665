import React from 'react'
import Login from '../../../Components/Admin/Login/Login'
import AdminNavbar from '../../../Components/Navbar/AdminNavbar'

function AdminLogin() {
  return (
    <>
    <AdminNavbar />
    <Login/>
    </>
  )
}

export default AdminLogin
